/* You can add global styles to this file, and also import other style files */

// * {
//   box-sizing: border-box;
// }
.card {

  text-align: center;
  padding: 20px;
  border-radius: 1.5em !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  border: none !important;
 
  //background-color: #242427 !important;
}






.btn-primary,
.btn-check:checked+.btn {
  background-color: rgb(155, 137, 58) !important;
  border-color: rgb(136, 120, 49) !important;

}

.btn-primary:hover {
  background-color: rgb(136, 120, 49) !important;
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
  border-color: rgb(136, 120, 49) !important;

}

.title {
  font-weight: 700 !important;
  font-size: 2.5rem !important;
  line-height: 2rem !important;
  margin-bottom: 0.5rem !important;
}


input:focus,
textarea:focus {
  outline: none;
}


// th,
// td {
//   text-align: center;
//   font-size: 0.86rem;
// }

* {
  font-family: 'Gantari', sans-serif;
  // color: white;
}

body,
html {
  margin: 0;
  padding: 0;

}



.ui.button {

  background: #3a3a3d none repeat scroll 0 0 !important;
  color: white !important;

}

a {
  color: rgb(155, 137, 58) !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

a:hover {
  color: rgb(107, 95, 38) !important;
  font-weight: 700 !important;
}










































.title {
  font-size: xx-large;
  font-weight: bolder;
}

.fx-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}


.wrapper nav.sidebar {
  background: #212121;
  // border-right: 1px #212121 solid;
  // box-shadow: none;
  direction: ltr;
  position: sticky;
  top: 0;
  height: 100vh;
}

.wrapper nav.sidebar .cajaBotones {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 27%;
  width: 35%;
  height: 270px;
  color: rgb(136, 134, 0);
}

.wrapper nav.sidebar .cajaBotones a {
  display: block;
  width: 100%;
  height: 90px;
  border: none;
  background: #ffffff;
  // box-shadow: 0px 0px 1px #212121;
  color: black;
  font-size: 20px;
  padding: 30px;
  text-align: center;
  text-decoration: none;
}

.wrapper nav.sidebar .cajaBotones a:hover {
  background-color: #ececec;
}


.wrapper {
  position: relative;
}

.tarjeta {
  top: 50px;
  position: relative;
  width: 300px;
  height: 366px;
  background: #8e8e8e;
  position: fixed;
  bottom: 30px;
  right: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index: 5000;
}

.tarjeta .form1 {
  width: 100%;
  height: 300px;
}

.tarjeta .form1 header {
  background: #fff;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e4e5;
}

.tarjeta .form1 input {
  height: 47px;
  margin-bottom: 4px;
  margin-top: 5px;
}

.tarjeta .form1 textarea {
  resize: none;
  height: 50%;
}

.tarjeta .form1 .btn {
  background: #fff;
  border: 1px solid #e3e4e5;
  width: 100%;
  height: 55px;
  border-radius: 5px;
  font-size: 25px;
  margin-top: 8px;
}

.tarjeta .form2 {
  width: 100%;
  height: 300px;
}

.tarjeta .form2 .box-msg {
  width: 100%;
  height: 300px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.tarjeta .form2 .box-msg .local {
  background: #838383;
  width: auto;
  max-width: 70%;
  height: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  text-align: left;
  padding: 10px 15px;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  color: white;
  align-self: flex-start;
  margin: 0.2%;
}

.tarjeta .form2 .box-msg .remoto {
  background: pink;
  width: auto;
  height: auto;
  max-width: 70%;
  font-size: 13px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
  padding: 10px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  text-align: left;
  align-self: flex-end;
  margin: 0.2%;
}

.box-msg p {
  margin: 0;
  text-align: left;
}

.tarjeta .form2 .input-msg {
  display: flex;
  background-color: #8e8e8e;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  padding: 5px;
}

.tarjeta .form2 .input-msg input {
  width: 80%;
  height: 43px;
}

.tarjeta .form2 .input-msg .btn {
  width: 20%;
  height: 43px;
  margin-left: 5px;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: lightblue;
}

// .tarjeta .form3 {
//   width: 100%;
//   height: 300px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .tarjeta .form3 .msg {
//   width: 100%;
//   height: 100%;
//   background: #ffffff;
//   border-radius: 10px;
//   display: flex;
//   justify-content: center;
//   flex-direction: column;
//   align-items: center;
//   overflow-y: auto;
//   overflow-x: hidden;
// }

.tarjeta .form4 {
  text-align: center;
  width: 100%;
  height: 300px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tarjeta .form5 {
  text-align: center;
  width: 100%;
  height: 300px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tarjeta .form4 .msg {
  width: 100%;
  height: 100%;
  background: #f0f0f0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn-flotante {
  border: none;
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  background: #d6d6d6;
  right: 50px;
  bottom: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 7px;
  outline: none !important;
}

.btn-flotante img {
  outline: none;
  border: white;
  height: 30px;
  width: 30px;
}


// .cajaDropdown {
//   position: absolute;
//   top: 10px;
//   right: 30px;
//   width: 260px;
//   height: 260px;
//   background: white;
//   overflow: hidden;
//   border-radius: 10px;
//   flex-wrap: nowrap;
//   transition: margin-left 0.15s ease-in-out, left 0.15s ease-in-out,
//     margin-right 0.5s ease-in-out, right 0.15s ease-in-out;
//   margin: 20px auto;
//   display: none;
//   z-index: 5000;
// }

.bloque1 {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 260px;
  height: 260px;

  transition: all 0.15ms;
  transition: margin-left 0.15s ease-in-out;
}

.bloque2 {
  position: absolute;
  top: 0;
  right: -261px;
  width: 260px;
  height: 260px;
}





.sidebar.collapsed {
  margin-left: -260px !important;
}

@media (max-width: 400px) {
  .tarjeta {
    right: 10%;
  }

  .btn-flotante {
    right: 35%;
  }
}

@media (max-width: 400px) {
  .tarjeta {
    right: 6%;
  }
}

@media (max-width: 323px) {
  .tarjeta {
    width: 250px;
    right: 10%;
  }
}

@media (min-width: 1px) and (max-width: 990px) {
  .sidebar {
    margin-left: -260px;
  }

  .sidebar.collapsed {
    margin-left: 0 !important;
  }
}

@media (max-width: 449px) {
  .btn-flotante {
    // bottom: -120px;
    right: 15px;
    bottom: 10px;
  }
}

@media (max-width: 308px) {
  // .cajaDropdown {
  //   width: 220px !important;
  // }

  // .cajaDropdown .bloque1 {
  //   width: 220px;
  // }

  // .cajaDropdown .bloque2 {
  //   width: 220px;
  // }

  // .cajaDropdown * {
  //   font-size: 12px;
  // }
}



@media (max-width: 262px) {
  // .cajaDropdown {
  //   width: 150px !important;
  // }

  // .cajaDropdown .bloque1 {
  //   width: 150px;
  // }

  // .cajaDropdown .bloque2 {
  //   width: 150px;
  // }

  // .cajaDropdown * {
  //   font-size: 12px !important;
  // }
}